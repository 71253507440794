import * as React from "react";
import OpenChat from "./OpenChat";

const MainPresentationMeBox = (props) => {

    const {homepageData, includeDetails} = props

    return (
        <div className={"content_box p-8"}>
            <div className={"flex flex-column align-items-center align-middle justify-content-center"}>
                {homepageData.banner_category.text.split(",").map((el, k) => {
                    return (
                       <div key={k} className={"mb-2"}>
                           <div ><b>{el}</b></div>
                       </div>
                    )
                })}
            </div>

            {includeDetails && (
                <React.Fragment>
                    <div className={"flex flex-column align-items-center align-middle justify-content-center text_align_center"}>
                        <span className={"font_size_xxl text_green_light_color"}>Roxana Simionescu, terapeut atestat</span>
                    </div>

                    <div className={"flex flex-column align-items-center align-middle justify-content-center content_description"}>
                        {/*{homepageData.banner_description_about_me.text}*/}
                        <div
                            dangerouslySetInnerHTML={{__html: homepageData.banner_description_about_me.text}}
                        />
                    </div>

                </React.Fragment>
            )}

            <div className={"flex flex-column align-items-center align-middle justify-content-center"}>
                <div >
                    <OpenChat />
                </div>
            </div>

            <div
                className={"w-full flex flex-row justify-content-center align-middle align-items-center mb-8"}>
                <div className={"color_green_dark font-bold text_green_dark_color font_size_l"} >
                    <i>Prima sedinta este <b>GRATUITA!</b></i>
                </div>
            </div>
        </div>
    )
}

export default MainPresentationMeBox
