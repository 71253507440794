import * as React from "react"
import {Carousel} from "react-bootstrap";
import {GatsbyImage} from 'gatsby-plugin-image'
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyle = makeStyles(theme => ({
    circular_square: {
        borderRadius: '50%'
    }
}));



function TestimonialCarousel(props) {
    const {testimonials} = props;
    const classes = useStyle();

    const renderJsonLd = (node) => {
        return {
            "@type": "Review",
            "author": {
                "@type": "Person",
                "name": node.data.author_name.text
            },
            "datePublished": node.first_publication_date,
            "reviewBody": node.data.testimonial_content.text,
            "name": "Review - " + node.data.author_name.text,
            "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
            }
        }
    }

    return (
        <Carousel fade>
            {testimonials.edges.map((el, k) => {
                return (
                    <Carousel.Item key={k} >
                        <script
                            type="application/ld+json"
                            nonce={"mjkljkl78988KHJKLKkhkljkldsjgjkjjhjhjbhijsvgkjhoirfujkk"}
                            dangerouslySetInnerHTML={{ __html: JSON.stringify(renderJsonLd(el.node)) }}
                        />

                        <div className={"w-full flex flex-col align-middle justify-items-center align-items-center"} style={{ height: '600px', backgroundColor: '#183533', padding: '30px', color: 'white', paddingLeft: '30px', paddingRight: '30px'}}>
                            {el.node.data.author_image.gatsbyImageData !== null && (
                                <GatsbyImage image={el.node.data.author_image.gatsbyImageData} className={classes.circular_square} alt={"Testimonial image"}/>
                            )}
                            <div className={"mt-8"}>
                                <span style={{ fontSize: '24px', margin: '10px'}}>{el.node.data.author_name.text}</span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                                <span className="fa fa-star checked"></span>
                            </div>
                            <div>
                                <p className={"font_size_xl"}>{el.node.data.author_job.text}</p>
                            </div>
                            <div className={"mt-16"} style={{marginLeft: '20%', marginRight: '20%', textAlign: 'center'}}>
                                <p className={"font_size_l"}>{el.node.data.testimonial_content.text}</p>
                            </div>
                        </div>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}
export default TestimonialCarousel;