import * as React from "react"
import "../styles/image_mg_0269_base64.scss"
import "../styles/general.scss"
import {Grid} from "@material-ui/core";
import MainPresentationMeBox from "./MainPresentationMeBox";
import "../styles/main_presentation_desktop.scss";


const MainPresentationDesktop = ({ className, homepageData }) =>  {

    return (
       <React.Fragment>
           <Grid item sm={12} xl={12} lg={12} md={12} className={"w-full overlay_image_box_visible_desktop bck_green_light_color"}>
               <div className={className + " w-full  overlay_image_box background_image_mg_0269_base64"} >

                   <div className={"flex flex-row justify-content-end align-middle align-items-center w-full h-full secondary_presentation_desktop"} >
                       <div className={'header_content_box'}>
                            <MainPresentationMeBox homepageData={homepageData} includeDetails={true}/>
                       </div>
                   </div>
               </div>
           </Grid>
       </React.Fragment>
    )
}

export default MainPresentationDesktop
