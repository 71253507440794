import * as React from "react"
import "../styles/image_mg_0269_base64.scss"
import "../styles/index.scss"
import {Grid} from "@material-ui/core";
import OpenChat from "./OpenChat";

const MainPresentationMobile = ({ className, homepageData }) =>  {

    return (
        <React.Fragment>
            <Grid item sm={12} xl={12} lg={12} md={12} className={"w-full overlay_image_box_visible_phone"}>
                <div className={"w-full h-full overlay_image_box_visible_phone  background_image_mg_0269_base64"}>
                </div>
            </Grid>

            <Grid item sm={12} xl={12} lg={12} md={12} className={"w-full overlay_image_box_visible_phone p-8"}>
                <div className={"mb-16 flex flex-row justify-content-center align-middle align-items-center w-full font_size_xl mobile_my_name"}>
                    <span><b>Roxana Simionescu - psiholog atestat</b></span>
                </div>
            </Grid>
            <Grid item sm={12} xl={12} lg={12} md={12} className={"w-full overlay_image_box_visible_phone"}>
                <div className={"mb-16 flex flex-row justify-content-center align-middle align-items-center w-full"}>
                    {/*<MainPresentationMeBox homepageData={homepageData} includeDetails={false}/>*/}
                    <OpenChat />
                </div>
            </Grid>
        </React.Fragment>
    )
}

export default MainPresentationMobile
