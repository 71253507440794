import * as React from "react"
import Seo from "../seo"
import "./../../styles/index.scss"
import {Grid} from "@material-ui/core";
import MainPresentationDesktop from "../MainPresentationDesktop";
import OverviewMainServices from "../OverviewMainServices";
import MainMenu from "../MainMenu";
import MainPresentationMobile from "../MainPresentationMobile";
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect, useMemo} from "react";
import TestimonialCarousel from "../TestimonialCarousel";
import OpenChat from "../OpenChat";
import Banner from "../Banner";

const ComponentHomepate = ({ data }) => {
    const homepageData = data.prismicHomepage.data
    const prismicContact = data.prismicContact
    const testimonials = data.allPrismicTestimonial
    const allPrismicServices = data.allPrismicServices

    useEffect(() => {
        Mixpanel.track('Page-Homepage');
    }, [data])

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/",
        title: 'Acasa - Cabinet psihologic sector 6',
        description: 'Cabinet psihologic ,sector 6, avize medicale online, sedinte terapie, clinica medicala, online. Roxana Simonescu psiholog atestat',
        url: "https://www.cabinet-psiho.ro/",
        metas: [
        ]
    }

    const renderUsingMemo = () => (
        <div className={"index_page"}>
            <Seo title="Acasa" seoData={seoData}/>
            <Grid container spacing={0}>

                <MainMenu pageName={"index"}  prismicContact={prismicContact}/>

                <Banner />

                <MainPresentationDesktop homepageData={homepageData} className={"mb-16"}/>

                <MainPresentationMobile homepageData={homepageData}/>

                {/*<MainPresentationLargeBox homepageData={homepageData} />*/}

                <OverviewMainServices
                    allPrismicServices={allPrismicServices}
                    prismicContact={prismicContact}
                    testimonials={testimonials}
                />

                <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16  mb-16"}>
                    <OpenChat />
                </div>

                <Grid item sm={12} xl={12} lg={12} md={12} className={"w-full"}>
                    <TestimonialCarousel testimonials={testimonials}/>
                </Grid>

                <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16 mb-16"}>
                    <OpenChat />
                </div>

                <AppFooter prismicContact={prismicContact} marginTop={'0'}/>
            </Grid>
        </div>
    )

    const memoizedValue = useMemo(() => renderUsingMemo(), [homepageData]);

    return (<>{memoizedValue}</>)

}



export default ComponentHomepate
