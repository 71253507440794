import * as React from "react"
import {Grid} from "@material-ui/core";
import ServiceList from "./ServiceList";
import '../styles/general.scss';

const OverviewMainServices = ({ allPrismicServices, prismicContact, testimonials }) =>  {
    return (
        <Grid item sm={12} xl={12} lg={12} md={12}>
                <div className={"mb-8 w-full flex flex-column justify-content-center align-middle font-bold align-items-center font_size_xl text_black_color"}>
                    <div className={'font-bold text_green_light_color font_size_xxl'} >Servicii</div>
                    <div>
                        Cu ce te pot ajuta
                    </div>
                </div>
                <ServiceList
                    allPrismicServices={allPrismicServices}
                    pageContext={{id: null}}
                    showTitle={false}
                    prismicContact={prismicContact}
                    testimonials={testimonials}
                    showSearch={false}
                    showDetails={false}
                />
        </Grid>
    )
}

export default OverviewMainServices
